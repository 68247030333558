function isEmptyObject(obj) {
  // 检查参数是否为对象
  if (typeof obj !== 'object' || obj === null) {
    return true
  }

  // 检查对象是否有可枚举的属性
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

function customCell(record, index, list, key) {
  let rowSpan = mergeCells(record[key], list, key, index)
  return rowSpan > 0
    ? {
        attrs: {
          rowSpan,
        },
      }
    : {
        style: {
          display: 'none',
        },
        attrs: {
          rowSpan: 0,
        },
      }
}
function mergeCells(text, data, key, index) {
  // 上一行该列数据是否一样
  if (index !== 0 && text === data[index - 1]?.[key]) {
    return 0
  }
  let rowSpan = 1
  // 判断下一行是否相等
  for (let i = index + 1; i < data.length; i++) {
    if (text !== data[i][key]) {
      break
    }
    rowSpan++
  }
  return rowSpan
}

export { isEmptyObject, customCell }
